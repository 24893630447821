@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap');

/*html {*/
/*    overflow-y: scroll;*/
/*    scroll-behavior: smooth;*/
/*}*/
html {
    overflow: -moz-scrollbars-vertical;
    overflow-y: scroll;
}
ul {
    padding-left: 16px;
}
ul li {
    font-family: 'Avenir', sans-serif;
    font-size: 16px;
}

.parallax {
    perspective: 1px;
    min-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
}
.parallax__layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.parallax__layer--base {
    transform: translateZ(0);
}
.parallax__layer--back {
    transform: translateZ(-1px) scale(2);
}

a {
    outline: none !important;
    text-decoration: none;
}

.hyperlink {
    text-decoration: none;
    color: black;
}
.hyperlink:hover {
    text-decoration: underline;
    color: black;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active
{
    -webkit-box-shadow: 0 0 0 30px white inset;
    -webkit-text-fill-color: black;
}

.darkInputs input:-webkit-autofill,
.darkInputs input:-webkit-autofill:hover,
.darkInputs input:-webkit-autofill:focus,
.darkInputs input:-webkit-autofill:active
{
    -webkit-box-shadow: 0 0 0 30px rgb(58, 82, 115) inset;
    -webkit-text-fill-color: white !important;
    background:url('./assets/images/fill.png') repeat-x !important;
}

.darkInputs input,
.darkInputs input:active,
.darkInputs input:focus,
.darkInputs input:hover,
.darkInputs input:active{
    background:rgb(58, 82, 115) !important;
    color:white !important;
}

.whiteInputs input:-webkit-autofill,
.whiteInputs input:-webkit-autofill:hover,
.whiteInputs input:-webkit-autofill:focus,
.whiteInputs input:-webkit-autofill:active
{
    -webkit-box-shadow: 0 0 0 30px white inset;
    background:white repeat-x !important;
}

.whiteInputs input,
.whiteInputs input:active,
.whiteInputs input:focus,
.whiteInputs input:hover,
.whiteInputs input:active{
    background:white !important;
}



/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
